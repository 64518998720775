import React, { useEffect, useState } from 'react'

import { Unidades } from '@monorepo-amais/commons/components/unidades'
import Layout from '../components/layout'

import SEO from '../components/seo'

import { graphql } from 'gatsby'
import { calcRadius, getGeolocation } from '@monorepo-amais/commons/utils/calcs'

const UnidadesPage = ({ data }) => {
	const [location, setLocation] = useState()
	const [units, setUnits] = useState(
		data.allUnidades.edges.map(({ node }) => {
			const { id, cep, slug, ...others } = node
			return {
				id: id,
				slug: slug,
				cep: cep.replace(/\D+/g, ''),
				...others
			}
		})
	)

	useEffect(() => {
		getGeolocation(setLocation)
	}, [])

	useEffect(() => {
		if (location) {
			setUnits(
				data.allUnidades.edges
					.map(({ node }) => {
						const { id, cep, latitude, longitude, slug, ...others } = node
						return {
							id: id,
							slug: slug,
							distance: calcRadius({ lat: latitude, lon: longitude, location }),
							cep: cep.replace(/\D+/g, ''),
							latitude,
							longitude,
							...others
						}
					})
					.sort((a, b) => a.distance - b.distance)
			)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location])

	const facilities = data.allFacilidades.edges
		.map(({ node }) => ({
			value: node.id,
			label: node.title,
			...node,
			count: units.filter(item => !!item.facilidades.find(facility => facility.id === node.id)).length
		}))
		.filter(facility => !!facility.count)
		.sort((a, b) => (a.label > b.label ? 1 : -1))

	// Copiando o que o Jader fez em cima para as Regiões
	// Pelo oq eu entendi, ele arruma o objeto para se encaixar na lógica de filtro
	// e faz a contagem de quantas unidades tem aquela região
	const regions = data.allRegiao.edges
		.map(({ node }) => ({
			value: node.regiao,
			label: node.regiao,
			slug: encodeURIComponent(node.regiao),
			...node,
			count: units.filter(item => item.regiao === node.regiao).length
		}))
		.filter(({ count }) => !!count)
		.sort((a, b) => (a.label > b.label ? 1 : -1))

	return (
		<Layout>
			<SEO title='Unidades de Atendimento > Laboratórios' />
			<Unidades
				units={units}
				facilities={units.length && facilities}
				regions={units.length && regions}
				setUnits={setUnits}
				latlong={location}
			/>
		</Layout>
	)
}

export default UnidadesPage

export const query = graphql`
	query {
		allUnidades {
			edges {
				node {
					id: idLegado
					slug
					bairro
					latitude
					longitude
					cep
					regiao
					facilidades {
						id: idCosmic
					}
					endereco
					nome
					fotos {
						imagem {
							imgix_url
							url
						}
					}
					thumbnail {
						imagem {
							imgix_url
							url
						}
					}
					destaque
				}
			}
		}
		allFacilidades {
			edges {
				node {
					id
					title
					slug
				}
			}
		}
		allRegiao {
			edges {
				node {
					id
					regiao
				}
			}
		}
	}
`
